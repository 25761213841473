import {DeploymentType, Language} from 'api/entities';
import {LocalStorageKeys} from '../../constants/local-storage-keys.constant';
import {CurrentUser} from '../user/avelon-user.interface';
import {Alcedo7User} from '../user/avelon-user.service';

export class UserSessionService {
  static isReporting = false;
  static isSlideShow = false;

  static isSlideShowOrReporting(): boolean {
    return UserSessionService.isSlideShow || UserSessionService.isReporting;
  }

  // Check when application is loaded on the On Site Panel Touchscreen itself.
  // The application can also be loaded from a normal browser but served from On Site Panel.
  static isOnSitePanel(deploymentType: DeploymentType): boolean {
    return deploymentType === DeploymentType.ON_SITE_PANEL;
  }

  static setSelectedClientId(clientId: number): void {
    localStorage.setItem(LocalStorageKeys.UserSession.iotAppSelectedClientIdKey, clientId.toString());
  }

  static getSelectedClientId(): number {
    return +localStorage.getItem(LocalStorageKeys.UserSession.iotAppSelectedClientIdKey);
  }

  private static setUserGUID(guid: string): void {
    localStorage.setItem(LocalStorageKeys.UserSession.userGuidKey, guid);
  }

  static setLanguageChange(): void {
    // notify the other tabs to refresh tokens and reload
    localStorage.setItem(LocalStorageKeys.UserSession.languageChangeKey, Date.now().toString());
  }

  static getSessionDummy(): string {
    return localStorage.getItem(LocalStorageKeys.UserSession.sessionDummyKey);
  }

  static setSessionDummy(): void {
    localStorage.setItem(LocalStorageKeys.UserSession.sessionDummyKey, 'true');
  }

  static removeSessionDummy(): void {
    localStorage.removeItem(LocalStorageKeys.UserSession.sessionDummyKey);
  }

  static getToken(): string {
    // only in memory used for reporting and slideshow
    return Alcedo7User.token;
  }

  static setToken(token: string): void {
    Alcedo7User.token = token;
  }

  static clearUserFromMemory() {
    Alcedo7User.currentUser = {};
    Alcedo7User.clients = [];
  }

  static softDestroy() {
    // keep the old keys, to clear the old auth info
    localStorage.removeItem(LocalStorageKeys.UserSession.stayLoggedInKey); // deprecated
    localStorage.removeItem(LocalStorageKeys.UserSession.accessTokenKey); // deprecated
    localStorage.removeItem(LocalStorageKeys.UserSession.refreshTokenKey); // deprecated
    localStorage.removeItem(LocalStorageKeys.UserSession.userGuidKey);
    localStorage.removeItem(LocalStorageKeys.UserSession.iotAppSelectedClientIdKey);
    UserSessionService.clearUserFromMemory();
  }

  static destroy(): void {
    UserSessionService.removeSessionDummy();
    localStorage.clear();
    UserSessionService.clearUserFromMemory();
  }

  static removeStoreRef(): void {
    sessionStorage.removeItem(LocalStorageKeys.UserSession.urlRefKey);
  }

  static storeRef(): void {
    sessionStorage.setItem(LocalStorageKeys.UserSession.urlRefKey, location.pathname + location.search);
  }

  static getRef(): string {
    return sessionStorage.getItem(LocalStorageKeys.UserSession.urlRefKey);
  }

  static getHeaderToken(token?: string): {[key: string]: string} {
    const authToken = token || UserSessionService.getToken();
    if (authToken) {
      return {
        'ngsw-bypass': '', // service worker do not intercept XHR requests
        Authorization: 'Bearer ' + authToken
      };
    } else {
      return {
        'ngsw-bypass': '' // service worker do not intercept XHR requests
      };
    }
  }

  static getUserGUID(): string {
    let guid = localStorage.getItem(LocalStorageKeys.UserSession.userGuidKey);
    if (!guid) {
      guid = this.guidGen();
      UserSessionService.setUserGUID(guid);
    }
    return guid;
  }

  private static guidGen(): string {
    function _p8(s: boolean): string {
      const p = (Math.random().toString(16) + '000000000').substring(2, 10);
      return s ? '-' + p.substring(0, 4) + '-' + p.substring(4, 8) : p;
    }

    return _p8(false) + _p8(true) + _p8(true) + _p8(false);
  }

  static setCurrentUser(data: CurrentUser): void {
    Alcedo7User.currentUser = data;
    Alcedo7User.currentUser.language = Alcedo7User.currentUser.language || Language.EN_GB;
  }
}
